<template>
  <div>
    <el-dialog
      :show-close="true"
      fullscreen
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      append-to-body
      :title="$t('Kết nối TV')"
      custom-class="cs-modal"
    >
      <template slot="title">
        <div>
          <h2 class="text-3xl font-bold text-start m-0 px-4 text-black">Kết nối TV</h2>
          <el-divider class="mt-2 mb-0"/>
        </div>
      </template>
      <div class="w-full flex justify-center flex-col items-center h-100">
        <div class="list-number" v-loading="isLoadingOTP">
          <div v-for="(number,index) in code" :key="index" class="number shadow-md">{{ number }}</div>
        </div>
        <el-button
          type="primary"
          class="mt-4"
          style="width: 300px"
          @click="getOTP"
          :disabled="time_out > 0"
        >
          Lấy lại mã OTP
          <span v-if="time_out > 0">({{ time_out }}s)</span>
        </el-button>
        <div class="flex items-center gap-2" style="margin-top: 40px">
          <span class="open-now" @click="openTvMode">Mở TV Mode ngay tại đây</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {isDev} from '@/utils/constants'

export default {
  name: 'ModalConnectTvMode',
  components: {},
  props: {
    cr_id: Number
  },
  data () {
    return {
      code: [],
      dialogVisible: false,
      isLoadingOTP: false,
      code_expire_time: 0,
      time_out: 0,
      updated_at: '',
      interval_time_out: null
    }
  },
  computed: {},
  created () {
  },
  methods: {
    openDialog (isShow = true) {
      if (isShow) {
        this.getOTP()
      }
      this.dialogVisible = true
    },
    async getOTP () {
      try {
        this.isLoadingOTP = true
        const res = await this.$rf.getRequest('DoctorRequest').getOtpTvMode({
          cr_id: this.cr_id
        })
        this.code = res?.data?.code
        this.code_expire_time = res?.data?.code_expire_time
        this.updated_at = res?.data?.updated_at
        this.setIntervalTimeOut()
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoadingOTP = false
      }
    },
    handleClose () {
      this.openDialog(false)
      this.dialogVisible = false
    },
    openTvMode () {
      const origin = window.location.origin
      let domain = ''

      if (isDev) {
        if (origin.includes('localhost')) {
          domain = `http://localhost:9051/#/?verification_code=${this.code}`
        } else {
          domain = `https://dev.tv.hodo.pro/#/?verification_code=${this.code}`
        }
      } else {
        domain = `https://tv.hodo.pro/#/?verification_code=${this.code}`
      }

      window.open(
        domain,
        '_blank',
        'directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,scrollbars=no,resizable=no,width=950,height=480'
      )
      // this.dialogVisible = false
    },
    setIntervalTimeOut () {
      const self = this
      self.time_out = Math.round(
        Number(this.code_expire_time - new Date(this.updated_at).getTime()) /
        1000
      )
      clearInterval(self.interval_time_out)
      self.interval_time_out = setInterval(() => {
        if (self.time_out > 0) {
          self.time_out--
        } else {
          clearInterval(self.interval_time_out)
        }
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .cs-modal {
    background-color: #f9fafb;
    overflow: hidden;
    height: 100vh;

    .el-dialog__header {
      background-color: white;
      padding-bottom: 0;
    }

    .el-dialog__body {
      padding: 40px;
      padding-right: 20px;
      padding-left: 66px;
      height: -webkit-fill-available;
    }
  }

  .hodo-el {
    .el-input__inner {
      background: white !important;
      border: 1px solid #dee2e6 !important;
      border-radius: 10px !important;
      width: 100%;
    }
  }

  .cs-radio {
    .el-radio__inner {
      width: 20px;
      height: 20px;
    }
  }

  .el-select {
    width: 100%;
  }

  .cs-uppercase {
    .el-input__inner {
      text-transform: uppercase;
    }
  }

  .cs-content {
    height: calc(100vh - 250px);

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.list-number {
  display: flex;
  align-items: center;
  gap: 24px;

  .number {
    padding: 20px;
    font-size: 56px;
    line-height: 64px;
    font-weight: 700;
    color: #000;
    border: 2px solid #20419b;
    border-radius: 8px;
  }
}

.open-now {
  font-size: 16px;
  color: #20419b;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
</style>
