<template>
  <div class="rounded-lg p-3 shadow-2xl bg-white mb-2">
    <div class="flex items-center">
      <div class="fs-20 fw-500">{{ name }}</div>
      <div
        @click="
          patientVisit.status !== EXAMINATION_STATUS.CODE.CANCEL
            ? openCreateIndication()
            : () => {}
        "
        class="cursor-pointer ml-auto p-2 w-36 rounded-lg border border-primary flex justify-center items-center"
      >
        <img src="@public/assets/images/icon/patientVisit/plus-circle.svg" alt />
        <p class="ml-2 text-primary font-weight-bold mb-0">{{ $t("schedule_page.lbl_designation") }}</p>
      </div>
    </div>

    <div class="mt-2" v-if="indicationOrders.length">
      <el-collapse
        class="hodo-el-collapse"
        v-model="activeCollapseItems"
        @change="handleChangeCollapse"
      >
        <el-collapse-item
          :name="`collapse-${index}`"
          v-for="(item, index) in indicationOrders"
          :key="item.id"
        >
          <template slot="title">
            <div
              @click="onHandleViewIndication(item)"
              @click.stop
              class="inline-block"
              role="button"
            >
              <div
                class="flex items-center font-medium txt-pri hover:text-blue-600 transition-all duration-200"
              >
                <span class="mr-1">
                  {{
                  `${$t(item.name)}${
                  item.indication.length
                  ? ` (${item.indication.length})`
                  : ""
                  }`
                  }}
                </span>
              </div>
              <div class="text-xs italic font-normal mt-1">
                <span class="mr-1">Cập nhật lần cuối:</span>
                <span>{{ getUpdatedAt(item) }}</span>
              </div>
            </div>
          </template>
          <div>
            <div
              v-for="(indication, index) in item.indication"
              :key="indication.id"
              :class="
                index < item.indication.length
                  ? 'border-b border-t-0 border-r-0 border-l-0 border-solid border-gray-200'
                  : ''
              "
              class="group flex justify-between hover:bg-gray-100 transition-all duration-200 px-2 py-1"
              role="button"
              @click="onHandleViewIndicationResult(indication)"
            >
              <div class="relative transition-all duration-200 w-full pr-2 cursor-pointer flex-1">
                <svg
                  v-if="indication && indication.document_link"
                  @click.stop="handleOpenResult(indication)"
                  class="cursor-pointer mr-1"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="0.5" y="0.5" width="29" height="29" rx="3.5" stroke="#20409B" />
                  <mask
                    id="mask0_35008_774"
                    style="mask-type: alpha"
                    maskUnits="userSpaceOnUse"
                    x="6"
                    y="6"
                    width="18"
                    height="18"
                  >
                    <rect
                      x="6.92859"
                      y="6.92871"
                      width="16.1429"
                      height="16.1429"
                      fill="#D9D9D9"
                      stroke="#20409B"
                    />
                  </mask>
                  <g mask="url(#mask0_35008_774)">
                    <path
                      d="M15 17.8701C15.8962 17.8701 16.6583 17.5563 17.2857 16.9289C17.913 16.3015 18.2268 15.5394 18.2268 14.6433C18.2268 13.7471 17.913 12.985 17.2857 12.3577C16.6583 11.7303 15.8962 11.4165 15 11.4165C14.1039 11.4165 13.3418 11.7303 12.7144 12.3577C12.087 12.985 11.7732 13.7471 11.7732 14.6433C11.7732 15.5394 12.087 16.3015 12.7144 16.9289C13.3418 17.5563 14.1039 17.8701 15 17.8701ZM15 16.5593C14.4676 16.5593 14.0154 16.3731 13.6428 16.0005C13.2702 15.6279 13.084 15.1757 13.084 14.6433C13.084 14.1108 13.2702 13.6587 13.6428 13.286C14.0154 12.9134 14.4676 12.7272 15 12.7272C15.5325 12.7272 15.9846 12.9134 16.3573 13.286C16.7299 13.6587 16.9161 14.1108 16.9161 14.6433C16.9161 15.1757 16.7299 15.6279 16.3573 16.0005C15.9846 16.3731 15.5325 16.5593 15 16.5593ZM15 19.9879C13.2644 19.9879 11.6835 19.5035 10.257 18.5347C8.83193 17.5668 7.7984 16.2698 7.15632 14.6433C7.7984 13.0168 8.83193 11.7197 10.257 10.7518C11.6835 9.78301 13.2644 9.29863 15 9.29863C16.7356 9.29863 18.3165 9.78301 19.743 10.7518C21.1681 11.7197 22.2016 13.0168 22.8437 14.6433C22.2016 16.2698 21.1681 17.5668 19.743 18.5347C18.3165 19.5035 16.7356 19.9879 15 19.9879Z"
                      fill="#20409B"
                      stroke="#20409B"
                      stroke-width="0.025"
                    />
                  </g>
                </svg>

                <span class="txt-pri">{{ $t(indication.name) }}</span>
              </div>
              <div class="mr-3">{{ `x ${indication.qty || 1}` }}</div>
              <div
                class="italic text-right whitespace-nowrap"
                :set="(status = hasExaminationResults(indication))"
              >
                <span :class="status.class">{{ status.text }}</span>
              </div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>

    <custom-modal
      :showModal="showIndicationModal"
      size="w-full"
      @hideModal="onHideModal"
      :title="
        indicationOrderDetail
          ? $t('Chỉnh sửa/ Thay đổi phiếu chỉ định')
          : $t('Tạo phiếu chỉ định')
      "
      titleClass="uppercase text-3xl w-full text-center"
      :esc-close="true"
    >
      <CUInficationOrder
        v-if="showIndicationModal"
        :type="type"
        :patient-visit="patientVisit"
        :patient_visit_id="consultationData.patient_visit_id"
        :mr_id="consultationData.medical_record_id"
        :hr_id="consultationData.health_record_id"
        :prop_indication_order="indicationOrderDetail"
        @refresh="onRefresh"
      />
    </custom-modal>

    <CUInficationOrderView
      v-if="showIndicationOrderDetail && indicationOrderDetail"
      :type="type"
      :patient-visit="patientVisit"
      :indicationOrderData="indicationOrderDetail"
      :showIndicationOrderDetail="showIndicationOrderDetail"
      @hideModal="onHideModal"
      @openUpdateIndication="openUpdateIndication"
      @onHandleViewIndicationResult="onHandleViewIndicationResult"
      @reloadPage="onReloadPage"
    />

    <ModalIndicationViewDetail
      v-if="showIndicationDetail"
      :patient-visit="patientVisit"
      :show-indication-detail="showIndicationDetail"
      :indication-detail-data="indicationDetail"
      :type="type"
      @hideModal="onHideModal"
      @reloadPage="onReloadPage"
    />
    <PdfViewer
      ref="PdfViewer"
      containerIDProps="ContainerIndicationPDFViewerID"
      elementIDProps="IndicationPDFViewerID"
    />
  </div>
</template>

<style scoped lang="scss">
::v-deep {
  .hodo-el-collapse {
    .el-collapse-item {
      &__header {
        height: auto;
        line-height: normal;
        padding: 8px 0;
      }

      &__wrap {
        border-bottom: none;
      }

      &__content {
        padding-bottom: 8px;
      }
    }
  }
}
</style>

<script>
import _ from 'lodash'
import moment from 'moment'
import CustomModal from '../../components/CustomModal'
import appUtils from '../../utils/appUtils'
import {
  ENVELOPE_DOCUMENT_TYPE,
  EXAMINATION_STATUS,
  INDICATION_STATUS
} from '../../utils/constants'
import CUInficationOrder from '../Indications/V2/CUInficationOrder'
import CUInficationOrderView from '../Indications/V2/CUInficationOrderView'
import ModalIndicationViewDetail from './Indication/ModalIndicationViewDetail'
import PdfViewer from '../PdfViewer/PdfViewer.vue'

export default {
  name: 'IndicationTemp',
  props: {
    name: {
      type: String,
      required: true
    },
    type: {
      type: Number,
      required: true
    },
    consultation: {
      type: Object,
      required: true
    },
    patientVisit: {
      type: Object,
      required: true
    }
  },
  components: {
    CustomModal,
    CUInficationOrder,
    CUInficationOrderView,
    ModalIndicationViewDetail,
    PdfViewer
  },
  data () {
    return {
      appUtils,
      showIndicationModal: false,
      showIndicationOrderDetail: false,
      showIndicationDetail: false,
      consultationData: this.consultation || null,
      patientVisitData: this.patientVisit || null,
      indicationOrders: [],
      indicationOrderDetail: null,
      indicationDetail: {},
      INDICATION_STATUS,
      activeCollapseItems: ['collapse-0'],
      EXAMINATION_STATUS
    }
  },
  created () {
    this.consultationData = this.consultation

    const _tempIndicationOrders = _.filter(
      this.consultationData.indication_order,
      { cate: this.type }
    )
    this.indicationOrders = _tempIndicationOrders
    this.indicationOrders.map((item, index) => {
      this.activeCollapseItems.push(`collapse-${index}`)
    })
  },
  watch: {
    '$route.query': {
      handler: function () {
        this.handleOpenModalWithRouteQuery()
      },
      deep: true,
      immediate: true
    },
    consultation (data) {
      if (data) {
        this.consultationData = data

        const _tempIndicationOrders = _.filter(
          this.consultationData.indication_order,
          { cate: this.type }
        )
        this.indicationOrders = _tempIndicationOrders
        this.indicationOrders.map((item, index) => {
          this.activeCollapseItems.push(`collapse-${index}`)
        })

        if (!this.$route.query) return
        if (this.$route.query?.iorder) {
          const id = this.$route.query?.iorder
          const data = this.indicationOrders.find(
            item => item.id === Number(id)
          )
          if (!data || typeof data === 'undefined') return
          this.indicationOrderDetail = data
        }
      }
    },
    patientVisit (data) {
      if (data) {
        this.patientVisitData = data
      } else {
        this.patientVisitData = null
      }
    }
  },
  mounted () {
    this.handleOpenModalWithRouteQuery()
  },
  methods: {
    handleOpenModalWithRouteQuery () {
      const self = this
      if (!self.$route.query) return
      if (self.$route.query?.iorder) {
        const id = self.$route.query?.iorder
        const data = self.indicationOrders.find(item => item.id === Number(id))
        if (!data || typeof data === 'undefined') return
        self.onHandleViewIndication(data)
        return
      }

      if (self.$route.query?.indication) {
        const id = self.$route.query?.indication
        let data = null
        // eslint-disable-next-line no-unused-expressions
        self.indicationOrders?.map(order => {
          if (data) return
          data = order?.indication?.find(item => item?.id === Number(id))
        })
        self.onHandleViewIndicationResult(data)
      }
    },
    handleChangeCollapse (val) {
      // console.log(val)
    },
    getIndicationStatus (stt) {
      switch (stt) {
        case INDICATION_STATUS.CODE.PENDING:
          return {
            text: this.$t('Chưa có KQ'),
            class: `text-yellow-600`
          }
        case INDICATION_STATUS.CODE.HAS_RESULT:
          return {
            text: this.$t('Đã có KQ'),
            class: `text-green-700`
          }
        case INDICATION_STATUS.CODE.CS:
          return {
            text: this.$t('Có dấu hiệu bất thường'),
            class: `text-red-600`
          }
        case INDICATION_STATUS.CODE.NCS:
          return {
            text: this.$t('Không có dấu hiệu bất thường'),
            class: `text-blue-800`
          }

        default:
          break
      }
    },
    hasExaminationResults (data) {
      if (data?.examination_result?.id) {
        return {
          text: this.$t('Đã có KQ'),
          class: `text-green-700`
        }
      }
      if (data?.status === 3) {
        return {
          text: this.$t('Đã hủy'),
          class: `text-red-600`
        }
      } else {
        return {
          text: this.$t('Chưa có KQ'),
          class: `text-yellow-600`
        }
      }
    },
    openCreateIndication () {
      this.indicationOrderDetail = null
      this.showIndicationModal = true
    },
    onHideModal (hasQuery, isGoBack) {
      this.showIndicationModal = false
      this.showIndicationOrderDetail = false
      this.showIndicationDetail = false

      if (hasQuery) {
        if (isGoBack) {
          this.$router.go(-1)
        } else {
          this.$router.replace({ query: null })
        }
      }
    },
    onRefresh (isHide = true) {
      isHide && this.onHideModal()
      this.$emit('refresh')
    },
    onReloadPage () {
      this.$emit('refresh')

      if (this.$route.query?.iorder) {
        const id = this.$route.query?.iorder
        const data = this.indicationOrders?.find(item => item.id === Number(id))
        this.indicationOrderDetail = data
      }
    },
    onHandleViewIndication (item) {
      if (!item || typeof item === 'undefined') return

      this.indicationOrderDetail = item
      this.showIndicationOrderDetail = true

      if (Number(this.$route.query?.iorder) === Number(item?.id)) return

      this.$router.push({
        query: {
          iorder: item?.id
        }
      })
    },
    openUpdateIndication (item) {
      if (!item || typeof item === 'undefined') return

      this.showIndicationModal = true
      this.indicationOrderDetail = item
    },
    onHandleViewIndicationResult (item) {
      if (!item || typeof item === 'undefined') return

      if (item?.status === 3) {
        this.$message({
          type: 'warning',
          message: this.$t('Xét nghiệm này đã hủy, bạn không thể xem chi tiết.')
        })
        return
      }

      this.indicationDetail = item
      this.showIndicationDetail = true

      if (Number(this.$route.query?.indication) === Number(item?.id)) return

      this.$router.push({
        query: {
          indication: item?.id
        }
      })
    },
    handleOpenResult (indication) {
      this.$refs.PdfViewer.handleOpen(
        indication?.document_link,
        ENVELOPE_DOCUMENT_TYPE.INDICATION_RESULT,
        indication.id
      )

      // window.open(indication.document_link, '_blank')
    },
    getUpdatedAt (item) {
      const date = moment(item.updated_at)
      return appUtils.formatDateTimeFull(date)
    }
  }
}
</script>
