<template>
  <div>
    <el-dialog
      :visible.sync="isOpen"
      width="60%"
      center
      custom-class="modal-list-hsba"
    >
      <template slot="title">
        <div class="fs-20 font-bold uppercase">
          {{ $t("Danh sách Hồ sơ bệnh án") }}
        </div>
        <small>(Chưa đính kèm lịch tái khám)</small>
      </template>
      <div class="flex items-center gap-3 mb-3">
        <span style="min-width: 80px" class="font-semibold">Tìm kiếm</span>
        <el-input
          v-model="keyword"
          @input="handleSearch"
          placeholder="Nhập tên HSBA"
        ></el-input>
      </div>
      <div
        v-loading="isLoading"
        class="overflow-auto"
        style="max-height: 500px"
      >
        <el-table
          ref="multipleTable"
          :data="tableData"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <!-- <el-table-column label="Tên bệnh nhân" width="180">
            <template slot-scope="scope">{{ scope.row.person.name }}</template>
          </el-table-column>-->

          <el-table-column label="Tên bệnh án">
            <template slot-scope="scope">{{
              scope.row.medical_record_name
            }}</template>
          </el-table-column>
          <!-- <el-table-column label="Bác sĩ điều trị" width="220">
            <template slot-scope="scope">{{
              scope.row.medical_record_code
            }}</template>
          </el-table-column> -->
          <el-table-column label="Ngày tạo" show-overflow-tooltip>
            <template slot-scope="scope">{{
              appUtils.formatDateTime(scope.row.created_at)
            }}</template>
          </el-table-column>
        </el-table>
      </div>
      <template slot="footer">
        <div class="flex justify-end">
          <el-button @click="isOpen = false">Huỷ</el-button>
          <el-button
            type="primary"
            @click="handleSave"
            :loading="isLoadingBtn"
            :disabled="!multipleSelection.length"
            >Lưu</el-button
          >
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import { debounce } from 'lodash'

export default {
  name: 'ModalListHSBA',
  props: {
    person_id: Number,
    appt_id: Number,
    currentPatientVisit: Object
  },
  data () {
    return {
      isOpen: false,
      multipleSelection: [],
      tableData: [],
      pagination: {
        currentPage: 1,
        pageSize: 100,
        totalItems: 0
      },
      isLoading: false,
      isLoadingBtn: false,
      appUtils,
      keyword: ''
    }
  },
  methods: {
    handleOpen () {
      this.isOpen = true
      this.multipleSelection = []
      // this.$refs.multipleTable.clearSelection()
      this.fetchListBADT()
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    async handleSave () {
      await this.linkReExamToBADT()
    },
    async linkReExamToBADT () {
      this.isLoadingBtn = true
      const params = {
        appt_id: this.appt_id,
        medical_record_ids: this.multipleSelection?.map((elm) => elm.id),
        examination_date: window
          .moment(this.currentPatientVisit.start_time)
          .unix()
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .linkReExamToBADT(params)
        .then(() => {
          this.isLoadingBtn = false
          this.$toast.open({
            message: 'Đính kèm lịch hẹn khám lại vào HSBA thành công!',
            type: 'success'
          })
          this.$emit('success')
          this.isOpen = false
        })
        .catch((error) => {
          this.$toast.open({
            message:
              'Đính kèm lịch hẹn khám lại vào HSBA thất bại, vui lòng thử lại!',
            type: 'warning'
          })
          this.isLoadingBtn = false
          console.log(error)
        })
    },
    async fetchListBADT () {
      this.isLoading = true
      if (!this.$globalClinic && !this.$globalClinic.id) return
      let self = this
      const params = {
        clinic_id: this.$globalClinic.id,
        page: this.pagination.currentPage,
        sort_by: 'created_at',
        order: 'desc',
        page_size: this.pagination.pageSize,
        keyword: this.keyword,
        person_id: this.person_id,
        is_attach_reexam: 1, // 1: Những BADT chưa đính kèm lịch tái khám, 2: Những BADT đã đính kèm lịch tái khám'
        appt_id: this.appt_id
      }
      const response = await this.$rf
        .getRequest('DoctorRequest')
        .fetchBADTByApptId(params)
        .then((res) => {
          this.tableData = res?.data?.data
          this.isLoading = false
        })
        .catch((e) => {
          console.log(e)
          self.$toast.open({
            message: 'BADT không tồn tại',
            type: 'error'
          })
          return false
        })
      return response
    },
    handleSearch: debounce(function (value) {
      this.keyword = value
      this.fetchListBADT()
    }, 500)
  }
}
</script>
<style lang="scss" >
.modal-list-hsba {
  //   margin-top: 2vh !important;
  .el-dialog__body {
    // padding: 0 16px 16px;
  }
}
.el-input {
  .el-input__inner {
    border: 1px solid #dbdbdb !important;
  }
}
</style>