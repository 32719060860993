<template>
  <div>
    <el-dialog title="Warning" :visible.sync="isOpen" width="60%" center>
      <template slot="title">
        <div class="fs-20 font-bold uppercase">
          {{ $t(isUpdate ? "Cập nhật phiếu thu" : "Tạo phiếu thu") }}
        </div>
      </template>

      <div v-loading="isLoading">
        <div>
          <label class="text-black">{{ $t("Tên Phiếu thu") }}</label>
          <el-input
            class="cs-input"
            v-model="receiptName"
            clearable
            :placeholder="$t('Nhập tên phiếu thu')"
          />
        </div>
        <div class="mt-3 cs-container">
          <label class="fs-18">{{
            $t("Danh sách chỉ định chưa thanh toán")
          }}</label>
          <div class="flex flex-col gap-2">
            <el-table
              ref="multipleTable"
              :data="serviceTransactions"
              style="width: 100%"
              row-key="id"
              @selection-change="handleSelectionChange"
            >
              <el-table-column reserve-selection type="selection" width="55">
              </el-table-column>
              <el-table-column prop="name" label="Tên Chỉ định" width="300px">
              </el-table-column>
              <el-table-column prop="service_code" label="Mã chỉ định">
              </el-table-column>
              <el-table-column
                align="center"
                prop="qty"
                class="text-center"
                label="Số lượng"
              >
              </el-table-column>
              <el-table-column
                align="right"
                :formatter="
                  (row) => appUtils.numberFormat((row && row.price) || 0, 'VND')
                "
                label="Giá chỉ định"
              >
              </el-table-column>
            </el-table>
          </div>

          <el-divider />
          <div class="flex justify-between items-center mt-2 text-black">
            <div class="font-bold fs-18">{{ $t("Tổng tiền:") }}</div>
            <div class="fs-18">
              {{ appUtils.numberFormat(totalMoney, "VND") }}
            </div>
          </div>
          <div class="mt-2 flex justify-between text-black">
            <strong class="font-bold fs-18">{{ $t("Bằng chữ: ") }}</strong>
            <span class="cs-text inline-block ml-2 fs-18 justify-between">
              <p>{{ appUtils.numberToWords(totalMoney || 0) }}</p>
            </span>
          </div>
        </div>
        <div
          class="flex flex-col md:flex-row items-center gap-2 justify-end mt-4"
        >
          <el-button @click="handleClose">{{ $t("Huỷ") }}</el-button>
          <el-button
            :disabled="
              !selectedTransaction || !selectedTransaction.length || isLoading
            "
            type="primary"
            @click="handleSubmit"
            >{{ $t(isUpdate ? "Lưu" : "Tạo phiếu") }}</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import { PAYMENT_TRANSACTION_TYPE } from '../../utils/constants'

export default {
  name: 'ModalCreateApptReceipt',
  data () {
    return {
      isLoading: false,
      isOpen: false,
      isUpdate: false,
      receiptName: 'Phiếu thu tiền cung cấp dịch vụ',
      selectedTransaction: [],
      serviceTransactions: [],
      appUtils,
      patientVisit: {},
      receiptDetail: {}
    }
  },
  computed: {
    totalMoney () {
      return (
        this.selectedTransaction?.reduce(
          (total, item) => item?.transaction_amount + total,
          0
        ) || 0
      )
    }
  },
  methods: {
    async handleOpen (updated, data, receiptInfo) {
      this.isOpen = true
      this.isUpdate = updated
      this.patientVisit = data
      if (this.$refs.multipleTable) {
        this.$refs.multipleTable.clearSelection()
      }

      await this.handleGetListServiceTransaction()
      if (receiptInfo && updated) {
        this.getDetailServiceTransactionSlip(receiptInfo.id)
      }
    },
    handleClose () {
      this.isOpen = false
      this.receiptName = 'Phiếu thu tiền cung cấp dịch vụ'
      this.selectedTransaction = []
      this.$refs.multipleTable.clearSelection()
    },
    async handleSubmit () {
      if (this.isUpdate) {
        this.updateApptReceipt()
        return
      }

      try {
        this.isLoading = true
        const params = {
          list_service_transaction_id:
            this.selectedTransaction?.map((item) => item?.id) || [],
          doctor_id: this.patientVisit?.doctor_id,
          ws_id: this.$globalClinic?.id,
          name: this.receiptName,
          reference_id: this.patientVisit?.appt_id,
          type: PAYMENT_TRANSACTION_TYPE.examination,
          person_id: this.patientVisit?.person_id,
          person_name: this.patientVisit?.person?.name,
          person_gender: this.patientVisit?.person?.gender,
          person_yob: this.patientVisit?.person?.birthday
            ? window.moment(this.patientVisit?.person?.birthday).format('YYYY')
            : '',
          address: this.patientVisit?.person?.address,
          amount: this.selectedTransaction?.reduce(
            (total, item) => total + (item?.transaction_amount || 0),
            0
          )
          // diagnostic:
        }

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .postCreateTransactionSlip(params)
        if (response.status === 200) {
          this.$toast.open({
            message: 'Tạo phiếu thu thành công',
            type: 'success'
          })

          this.handleClose()
          this.$emit('onRefresh')
        }
      } catch (error) {
        this.$toast.open({
          message: 'Tạo phiếu thu thất bại',
          type: 'error'
        })
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    handleSelectionChange (data) {
      console.log({ data })
      this.selectedTransaction = data
    },
    async handleGetListServiceTransaction () {
      if (!this.patientVisit?.appt_id) return

      try {
        const params = {
          page_num: 1,
          page_size: 1000,
          transaction_type: PAYMENT_TRANSACTION_TYPE.examination,
          transaction_type_id: this.patientVisit?.appt_id
          // payment_status: 1 // Chưa thanh toán
        }

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getListServiceTransaction(params)

        this.serviceTransactions = response?.data?.data || []
      } catch (error) {
        console.log(error)
      }
    },
    async getDetailServiceTransactionSlip (id) {
      if (!id) return

      try {
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .getDetailServiceTransactionSlip(id)
        this.receiptDetail = res.data || {}
        this.receiptName = this.receiptDetail.name || ''
        if (this.receiptDetail.service_transactions.length) {
          this.$refs.multipleTable.clearSelection()

          const serviceTransactionsResponse =
            this.receiptDetail.service_transactions?.map((item) => {
              this.$refs.multipleTable.toggleRowSelection(item, true)

              let itemInfo = {}

              if (item?.type_service === 1) {
                itemInfo = {
                  ...item,
                  name: item?.service_catalog?.name || '',
                  service_code: item?.service_catalog?.services_code,
                  price: item?.service_catalog?.price,
                  isSelect: true
                }
              } else {
                itemInfo = {
                  ...item,
                  name: item?.local_service?.local_name || '',
                  service_code:
                    item?.local_service?.service_catalog?.services_code,
                  price: item?.local_service?.local_price,
                  isSelect: true
                }
              }

              return itemInfo
            })

          this.serviceTransactions = this.serviceTransactions.concat(
            serviceTransactionsResponse
          )
        }
      } catch (error) {
        console.log(error)
      }
    },
    async updateApptReceipt () {
      if (!this.receiptDetail?.id) return
      try {
        this.isLoading = true

        const params = {
          list_service_transaction_id:
            this.selectedTransaction?.map((item) => item?.id) || [],
          name: this.receiptName,
          amount: this.selectedTransaction?.reduce(
            (total, item) => total + (item?.transaction_amount || 0),
            0
          )
        }
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .updateTransactionSlip(this.receiptDetail?.id, params)

        if (response.status === 200) {
          this.$toast.open({
            message: 'Cập nhật phiếu thu thành công',
            type: 'success'
          })
          this.handleClose()
          this.$emit('onRefresh')
        }
      } catch (error) {
        console.log(error)
        this.$toast.open({
          message: 'Cập nhật phiếu thu thất bại',
          type: 'error'
        })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .cs-input {
    .el-input__inner {
      border: 1px solid #dcdfe6 !important;
      background-color: white !important;
    }

    .el-input-group__append {
      background-color: white !important;
    }
  }
  .cs-container * {
    color: black;
  }
}

.cs-text {
  text-transform: lowercase;

  p::first-letter {
    text-transform: capitalize;
  }
}
</style>