<template>
  <div class="border p-4">
    <div class="hodo-print">
      <div class="overflow-hidden mb-3">
        <div class="text-center inline-block float-left">
          <h4 class="text-xl font-bold mb-0">
            {{
              $globalClinic && $globalClinic.organization
                ? $globalClinic.organization.authority_in_charge
                : ""
            }}
          </h4>
          <h5 class="text-lg font-bold mb-0">
            {{
              $globalClinic && $globalClinic.organization
                ? $globalClinic.organization.name
                : ""
            }}
          </h5>
          <h6 class="text-base font-bold mb-0">{{ $globalClinic && $globalClinic.name }}</h6>
        </div>
        <!-- <div class="inline-block float-right">
          <div class="text-base">
            <label class="mr-1">MS:</label>
            <span>09/BV-01</span>
          </div>
          <div class="text-base">
            <label class="mr-1">Số vào viện:</label>
            <span>{{ person.ref_id }}</span>
          </div>
        </div> -->
      </div>

      <div>
        <h1 class="text-center text-2xl font-bold uppercase mb-3">Kết quả xét nghiệm</h1>
      </div>

      <div>
        <div class="mb-2">
          <div class="inline-block" style="width: 50%;">
            <label class="mr-1">Họ và tên người bệnh:</label>
            <span class="font-bold">{{ patientInfo.name }}</span>
          </div>
          <div class="inline-block" style="width: 25%;">
            <label class="mr-1">Năm sinh:</label>
            <span class="font-bold">{{ patientInfo.birthYear }}</span>
          </div>
          <div class="inline-block" style="width: 25%;">
            <label class="mr-1">Giới tính:</label>
            <span class="font-bold">{{ patientInfo.gender }}</span>
          </div>
        </div>
        <div class="mb-2">
          <div class="inline-block" style="width: 100%;">
            <label class="mr-1">Địa chỉ:</label>
            <span class="font-bold">{{ patientInfo.address }}</span>
          </div>
        </div>
        <!-- <div class="mb-2">
          <div class="inline-block" style="width: 50%;">
            <label class="mr-1">Đối tượng:</label>
            <span class="font-bold"></span>
          </div>
          <div class="inline-block" style="width: 50%;">
            <label class="mr-1">Bác sĩ chỉ định:</label>
            <span class="font-bold">{{ indicator.indicator_name || (indicator.indicator && indicator.indicator.name)
              }}</span>
          </div>
        </div> -->
        <div class="mb-2">
          <div class="inline-block" style="width: 100%;">
            <label class="mr-1">Khoa:</label>
            <span class="font-bold">
              {{ $globalClinic && $globalClinic.name }}
            </span>
          </div>
        </div>
        <div class="mb-2">
          <div class="inline-block" style="width: 100%;">
            <label class="mr-1">Chẩn đoán:</label>
            <span class="font-bold">
              {{ diagnose }}
            </span>
          </div>
        </div>
        <div class="mb-2">
          <div class="inline-block" style="width: 50%;">
            <label class="mr-1">Người lấy mẫu:</label>
            <span class="font-bold">
              {{ generalInfo.sample_taker_name }}
            </span>
          </div>
          <div class="inline-block" style="width: 50%;">
            <label class="mr-1">Thời gian lấy mẫu:</label>
            <span class="font-bold">
              {{ generalInfo.take_sample_at }}
            </span>
          </div>
        </div>
        <div class="mb-2">
          <div class="inline-block" style="width: 50%;">
            <label class="mr-1">Người hoàn thành:</label>
            <span class="font-bold">
              {{ generalInfo.sample_receiver_name }}
            </span>
          </div>
          <div class="inline-block" style="width: 50%;">
            <label class="mr-1">Thời gian hoàn thành:</label>
            <span class="font-bold">
              {{ generalInfo.received_sample_at }}
            </span>
          </div>
        </div>
      </div>

      <div class="hodo-table mb-3">
        <!-- Result Test -->
        <table class="w-full" collapse border="1">
          <thead>
            <tr>
              <th class="text-center whitespace-nowrap">Xét nghiệm</th>
              <th class="text-center whitespace-nowrap">Kết quả</th>
              <th class="text-center whitespace-nowrap">Đơn vị</th>
              <th class="text-center whitespace-nowrap">Bình thường</th>
              <th class="text-center whitespace-nowrap">Máy XN</th>
              <th class="text-center whitespace-nowrap">Ghi chú</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="tableData && tableData.length">
              <tr v-for="(row, index) in tableData" :key="index">
                <template v-if="row.is_full_row">
                  <td colspan="6">
                    <div class="font-bold">{{ row.test_name }}</div>
                  </td>
                </template>
                <template v-else>
                  <td>
                    <div>{{ row.test_name }}</div>
                  </td>
                  <td>
                    <div class="text-center" :class="{ 'font-bold': row.is_alert }">
                      <span>{{ row.result }}</span>
                      <span class="ml-1"
                        :style="row.low_high === 'H' ? 'color: red !important' : row.low_high === 'L' ? 'color: blue !important' : ''"
                        v-if="row.low_high !== ''">({{ row.low_high }})</span>
                    </div>
                  </td>
                  <td>
                    <div class="text-center">{{ row.unit }}</div>
                  </td>
                  <td>
                    <div class="text-center">{{ row.normal_rate }}</div>
                  </td>
                  <td>
                    <div class="text-center">{{ row.test_machine }}</div>
                  </td>
                  <td>
                    <div :class="!row.note ? 'text-center' : ''">{{ row.note || "-" }}</div>
                  </td>
                </template>
              </tr>
            </template>
            <tr v-else>
              <td colspan="6">
                <div class="text-center">Không có dữ liệu kết quả xét nghiệm</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- <div class="mb-3">
        <div class="text-center align-top inline-block" style="width: 50%;">
          <div class="text-base mb-2">{{ dateNow }}</div>
          <div class="text-lg mb-5 font-bold">Bác sĩ điều trị</div>
        </div>
        <div class="text-center align-top inline-block" style="width: 50%;">
          <div class="text-base mb-2">{{ dateNow }}</div>
          <div class="text-lg mb-5 font-bold">Trưởng khoa xét nghiệm</div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import appUtils from '@/utils/appUtils'
import { mixinGetPersonInfo } from '@/utils/mixinGetPersonInfo'
import { mapState } from 'vuex'

export default {
  name: 'TestResultForm',
  mixins: [mixinGetPersonInfo],
  props: {
    tableData: Array,
    generalInfo: Object,
    patientVisit: Object
  },
  data () {
    // const generalInfoInitial = {
    //   diagnose: '',
    //   sample_taker_name: '',
    //   take_sample_at: '',
    //   sample_receiver_name: '',
    //   received_sample_at: '',
    //   patient_status: 0
    // }

    return {
      checkList: [],
      // tableData: this.tableData || [],
      // generalInfoInitial,
      // generalInfo: generalInfoInitial,
      appUtils
      // patientStatusList: ['No', 'Đói', 'Dùng thuốc', 'Khác']
    }
  },
  computed: {
    ...mapState({
      // person: (state) => state.person,
      // medicalRecordMain: (state) => state.medicalRecordMain
    }),
    person () {
      return this.patientVisit?.person || {}
    },
    dateNow () {
      let date = new Date()
      return `Ngày ${date.getDate().toString().padStart(2, '0')} tháng ${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, '0')} năm ${date.getFullYear()}`
    },
    patientInfo () {
      return {
        name: this.person.name,
        birthYear: this.person.birthday
          ? window.moment(this.person.birthday).format('YYYY')
          : '',
        gender: appUtils.getGender(this.person.gender),
        address: this.getAddress(this.person)
      }
    },
    diagnose () {
      let result = ''
      // if (
      //   this.medicalRecordMain &&
      //   this.medicalRecordMain.treat_dept_main_code
      // ) {
      //   result += this.medicalRecordMain.treat_dept_main_code
      // }
      // if (
      //   this.medicalRecordMain &&
      //   this.medicalRecordMain.treat_dept_main_name
      // ) {
      //   result = result + '-' + this.medicalRecordMain.treat_dept_main_name
      // }

      if (result === '' && this.generalInfo?.diagnose.length) {
        result += this.generalInfo?.diagnose
      }

      return result
    }
  },
  async mounted () {
    // await this.handleMounted()
  },
  watch: {
    // emrIndicationRead: {
    //   handler(data) {
    //     this.handleMounted(data)
    //   },
    //   deep: true,
    //   immediate: true
    // }
  },
  methods: {
    async handleMounted (data) {
      // this.tableData = []
      // this.generalInfo = {
      //   ...this.generalInfoInitial
      // }
      // const id = data?.id || this.emrIndicationRead?.id

      // if (!id) return

      // try {
      //   const res = await this.$rf.getRequest('DoctorRequest').getLisData(id)
      //   if (res?.data) {
      //     res.data.Data.forEach((exam_group) => {
      //       this.tableData = [
      //         ...this.tableData,
      //         { test_name: exam_group.name || exam_group.description, is_full_row: true }
      //       ]
      //       const resultArr = exam_group.examination_result_values.map((exam, idx) => {
      //         let lowHigh = Number(exam.lower) !== Number(exam.upper) ? (!isNaN(Number(exam.value)) ? (Number(exam.value) > Number(exam.upper) ? 'H' : Number(exam.value) < Number(exam.lower) ? 'L' : '') : '') : ''

      //         return {
      //           test_name: exam.name,
      //           result: exam.value || '-',
      //           low_high: lowHigh,
      //           unit: exam.unit || '-',
      //           normal_rate: Number(exam.lower) !== Number(exam.upper) ? `${exam.lower} - ${exam.upper}` : '-',
      //           test_machine: exam_group.device || '-',
      //           note: exam?.note || '',
      //           is_alert: lowHigh !== ''
      //         }
      //       })
      //       this.tableData = [...this.tableData, ...resultArr]
      //     })
      //     this.generalInfo = {
      //       ...res.data.meta,
      //       take_sample_at: appUtils.isDate(res.data.meta?.take_sample_at) ? appUtils.formatDateTimeFull(res.data.meta?.take_sample_at) : '',
      //       received_sample_at: appUtils.isDate(res.data.meta?.received_sample_at) ? appUtils.formatDateTimeFull(res.data.meta?.received_sample_at) : ''
      //     } || this.generalInfo
      //   }
      // } catch (err) {
      //   console.log(err)
      //   this.$message({
      //     message: 'Lấy dữ liệu xét nghiệm không thành công',
      //     type: 'warning'
      //   })
      // } finally {
      //   this.$emit('setTableIndicationsResult', this.tableData)
      // }
    },
    changeCheckbox (val) {
      this.generalInfo.patient_status = val
    },
    arraySpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (row.is_full_row) {
        if (columnIndex === 0) {
          return [1, 6]
        } else return [0, 0]
      }
    },
    getStatusSampleTest (data) {
      switch (Number(data)) {
        case 1:
          return this.$t('Đạt')
        case 2:
          return this.$t('Không đạt')
        default:
          return ''
      }
    }
  }
}
</script>
