<template>
  <div>
    <el-dialog title="Cảnh báo" :visible.sync="isOpen" width="40%" center>
      <div class="text-black">
        <div class="mb-2 fs-16">
          <strong style="word-break: break-word">{{
            $t("Xác nhận tạo yêu cầu cung cấp dịch vụ cho bệnh nhân cấp cứu")
          }}</strong>
        </div>
        <ul>
          <li style="word-break: break-word; margin-bottom: 4px">
            {{
              $t(
                "Bác sĩ tạo yêu cầu cung cấp dịch vụ trong trường hợp bệnh nhân cấp cứu cần thực hiện chỉ định cận lâm sàng."
              )
            }}
          </li>
          <li style="word-break: break-word">
            {{
              $t(
                "Bác sĩ đồng ý chịu trách nhiệm và bảo lãnh thanh toán đối với yêu cầu cung cấp dịch vụ cho bệnh nhân không phải tình huống cấp cứu."
              )
            }}
          </li>
          <span class="italic"
            >{{
              $t(
                "(Nếu không đồng ý, thì Bác sĩ yêu cầu Bệnh nhân thanh toán trước khi thực hiện dịch vụ)"
              )
            }}
          </span>
        </ul>

        <el-checkbox class="text-black" v-model="isConfirm">
          {{ $t("Đồng ý tạo yêu cầu cung cấp dịch vụ cấp cứu") }}</el-checkbox
        >
      </div>

      <div class="flex gap-2 justify-end mt-3">
        <el-button :disabled="isLoading" @click="handleClose">
          {{ $t("Huỷ bỏ") }}
        </el-button>
        <el-button
          :disabled="isLoading || !isConfirm"
          type="primary"
          @click="handleSubmit"
          >{{ $t("Tạo yêu cầu") }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'ModalConfirmCreateOrderEmergency',
  data () {
    return {
      isOpen: false,
      isLoading: false,
      receipt: {},
      isConfirm: false
    }
  },
  methods: {
    handleClose () {
      this.isOpen = false
      this.isConfirm = false
      this.receipt = {}
    },
    handleOpen (data) {
      this.isOpen = true
      this.receipt = data
    },
    handleSubmit () {
      this.$emit('onConfirm', this.receipt, undefined, 2)
      this.handleClose()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>